type Props = {
  className?: string
  variant?: string
  children?: React.ReactNode
}

const MainContainer = ({ children, variant = 'default', className = 'p-3' }: Props) => {
  return <div className={`main-container ${className}`}>{children}</div>
}

export default MainContainer
