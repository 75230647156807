import IScriptDetailItem from '../types/IScriptDetailItem'
import { Col, ListGroup, Row } from 'react-bootstrap'
import Avatar from './Avatar'

type Props = {
  scripts: IScriptDetailItem[]
  selectedScript?: IScriptDetailItem | null
  onScriptClicked: (s: IScriptDetailItem) => void
}

const ScriptList = ({ scripts, selectedScript = null, onScriptClicked }: Props) => {
  return (
    <ListGroup>
      <>
        {scripts &&
          scripts.map(s => (
            <ListGroup.Item
              key={s.id}
              action
              onClick={item => onScriptClicked(s)}
              active={s.number === selectedScript?.number}
            >
              <Row className='g-0'>
                <Col className='fw-bold'>#{s.number}</Col>
                <Col sm='auto' className='fs-8'>
                  {new Date(s.date).getDate() + '.' + (new Date(s.date).getMonth() + 1) + '. '}
                  <Avatar initials={s.userInitials} variant='small' isInternal={true} />
                </Col>
              </Row>
              {s.name}
            </ListGroup.Item>
          ))}
      </>
      {/* <div className='mb-2' key={script.number}>
            <ScriptCard
              script={script}
              selected={selectedScript ? selectedScript.number === script.number : false}
              onClick={() => onScriptClicked && onScriptClicked(script)}
            />
          </div> */}
    </ListGroup>
  )
}

export default ScriptList
