import axios from 'axios'
import { ChangeEvent, FormEvent, useContext, useState } from 'react'
import { Alert, Button, Col, Form, FormGroup, Row } from 'react-bootstrap'
import { useParams } from 'react-router'
import Loading from '../../comps/Loading'
import MainContainer from '../../comps/MainContainer'
import { API_BASE_URL } from '../../consts'
import MainLayout from '../../layouts/MainLayout'
import IScriptInsertItem from '../../types/IScriptInsertItem'
import { Link, useNavigate } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext'
import ScriptContext from '../../contexts/ScriptContext'
import { FaQuestionCircle } from 'react-icons/fa'

const ScriptNew = () => {
  const [db] = useState<number>(parseInt(useParams()?.db ?? '1'))

  const { scriptDbs } = useContext(ScriptContext)
  const [isLoadingScript, setIsLoadingScript] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')

  const { user } = useAuth()
  const navigate = useNavigate()

  const defaultScript: IScriptInsertItem = {
    db: db,
    name: '',
    isBreakingChange: false,
    sql: '',
    userName: user?.username ?? '',
  }
  const [script, setScript] = useState<IScriptInsertItem>(defaultScript)

  const saveScript = async () => {
    setIsLoadingScript(true)
    try {
      await axios.post(`${API_BASE_URL}/Script/Add`, script)
      navigate(`/Script/${db}`)
    } catch (error: any) {
      setErrorMessage(error.response.data.message)
    } finally {
      setIsLoadingScript(false)
    }
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
    const name = e.target.name
    const value = e.target.value
    setScript({ ...script, [name]: value })
  }

  const toggleBreakingCheck = () => {
    setScript({ ...script, isBreakingChange: !script.isBreakingChange })
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (!validateForm()) {
      alert('Vyplňte všechny údaje o skriptu')
      return
    }

    setErrorMessage('')

    saveScript()
  }

  const validateForm = (): boolean => {
    if (!user || !script) return false

    if (script.name === '') return false
    if (script.sql === '') return false
    return true
  }

  return (
    <MainLayout className='bg-light-gray'>
      <MainContainer className='mw-1200 p-3 bg-white'>
        <div className='pt-3 pb-3'>
          {isLoadingScript && <Loading />}
          {script && user && (
            <>
              <Row>
                <Col>
                  <h3 className='mb-3'>{scriptDbs[db - 1].name}</h3>
                </Col>
                <Col sm='auto'>
                  <Link to='/markdown/synchro-db-scripts-guide.md'>
                    <FaQuestionCircle className='me-1' />
                    Pravidla pro psaní skriptů
                  </Link>
                </Col>
              </Row>
              <Form onSubmit={e => handleSubmit(e)}>
                <FormGroup as={Row} className='mb-3'>
                  <Form.Label column sm={2}>
                    Breaking Change
                  </Form.Label>
                  <div className='col-auto d-flex flex-column justify-content-center'>
                    <input
                      type='checkbox'
                      className='form-check-input'
                      id='breakingCheck'
                      name='isBreakingChange'
                      defaultChecked={script.isBreakingChange}
                      onChange={toggleBreakingCheck}
                    />
                  </div>
                </FormGroup>
                <FormGroup as={Row} className='mb-3'>
                  <Form.Label column sm={2}>
                    Název
                  </Form.Label>
                  <Col sm={10}>
                    <input
                      type='text'
                      className='form-control'
                      id='inputTitle'
                      placeholder='Název...'
                      name='name'
                      value={script.name}
                      onChange={e => handleChange(e)}
                    />
                  </Col>
                </FormGroup>
                <FormGroup as={Row} className='mb-3'>
                  <Form.Label column sm={2}>
                    Sql
                  </Form.Label>
                  <Col sm={10}>
                    <textarea
                      style={{ minHeight: '30vh', height: '50%' }}
                      className='form-control'
                      id='inputSql'
                      placeholder='Sql...'
                      name='sql'
                      value={script.sql}
                      onChange={e => handleChange(e)}
                    />
                  </Col>
                </FormGroup>
                {errorMessage && (
                  <Row>
                    <Col>
                      <Alert variant='danger'>{errorMessage}</Alert>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col sm={2}></Col>
                  <Col>
                    <Button type='submit' variant='primary'>
                      Vytvořit nový script
                    </Button>
                  </Col>
                </Row>
              </Form>
            </>
          )}
        </div>
      </MainContainer>
    </MainLayout>
  )
}

export default ScriptNew
