import { Container, Nav, Navbar } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { getInitialsFromSingleName } from '../helpers/StringHelpers'
import Avatar from './Avatar'
import BatchErrorIcon from './BatchErrorIcon'
import './TopMenu.scss'
import WebOfflineIcon from './WebOfflineIcon'
import { useAuth } from '../contexts/AuthContext'

const TopMenu = () => {
  const { user } = useAuth()
  const navigate = useNavigate()

  return (
    <Navbar bg='dark' variant='dark' id='top-menu'>
      <Container fluid>
        <LinkContainer to='/' className='p-0 pb-1'>
          <Navbar.Brand>
            <img src='/images/devben.svg' width='85' alt='DevBen' />
          </Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle />
        <Navbar.Collapse>
          <Nav>
            <Link className='nav-link' to='/script'>
              Skripty
            </Link>
            <Link className='nav-link' to='/databases'>
              Databáze
            </Link>
            <Link className='nav-link' to='/sites'>
              Weby
            </Link>
            <Link className='nav-link' to='/admin-queries'>
              Query
            </Link>
            <Link className='nav-link' to='/kb'>
              KB
            </Link>
            <Link className='nav-link' to='/tools'>
              Nástroje
            </Link>
          </Nav>
        </Navbar.Collapse>
        <Nav className='me-3'>
          <Nav.Link onClick={() => navigate('/databases/batch-errors')}>
            <BatchErrorIcon />
          </Nav.Link>
          <Nav.Link onClick={() => navigate('/sites/offline')}>
            <WebOfflineIcon />
          </Nav.Link>
        </Nav>
        {user && (
          <Navbar.Text className='p-0'>
            <Avatar
              initials={getInitialsFromSingleName(user.name)}
              tooltip={user.name}
              isInternal={true}
            />
          </Navbar.Text>
        )}
      </Container>
    </Navbar>
  )
}

export default TopMenu
