export const API_UPTIME_BASE_URL = 'https://devzone-api.benefitcz.cz/api'

export const API_BASE_URL = 'https://devzone-api.benefitcz.cz/v1'
//export const API_BASE_URL = 'https://localhost:44369/v1'

//export const AUTH_REDIRECT_URI = 'http://localhost:3000'
export const AUTH_REDIRECT_URI = 'https://devzone.benefitcz.cz'

//export const BASE_URL = 'http://localhost:3000/'
export const BASE_URL = 'https://devzone.benefitcz.cz/'
