import axios from 'axios'
import { useState, useCallback, useEffect, useContext } from 'react'
import { Alert, Button, Col, Dropdown, FormControl, InputGroup, Row } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router'
import { API_BASE_URL } from '../../consts'
import MainLayout from '../../layouts/MainLayout'
import MainContainer from '../../comps/MainContainer'
import IScriptDetailItem from '../../types/IScriptDetailItem'
import './Script.scss'
import ScriptDetail from './comps/ScriptDetail'
import ScriptList from '../../comps/ScriptList'
import Loading from '../../comps/Loading'
import { Link } from 'react-router-dom'
import { FaPlus, FaRegEdit, FaDownload, FaTimes, FaQuestionCircle } from 'react-icons/fa'
import { useDebounce } from 'use-debounce'
import ScriptContext from '../../contexts/ScriptContext'

const Script = () => {
  const [num] = useState<number>(parseInt(useParams()?.num ?? '0'))
  const [db, setDb] = useState<number>(parseInt(useParams().db ?? '1'))
  const { scriptDbs } = useContext(ScriptContext)
  const [scripts, setScripts] = useState<IScriptDetailItem[]>([])
  const [script, setScript] = useState<IScriptDetailItem | null>(null)
  const [searchField, setSearchField] = useState<string>('')
  const [debouncedSearchField] = useDebounce(searchField, 1000)
  const [isLoadingScripts, setIsLoadingScripts] = useState<boolean>(false)
  const navigate = useNavigate()

  const searchScripts = useCallback(async () => {
    setIsLoadingScripts(true)
    const data = await axios.get<IScriptDetailItem[]>(
      `${API_BASE_URL}/Script/Search?db=${db}&pattern=${debouncedSearchField}`
    )
    setScripts(data.data)
    setIsLoadingScripts(false)
    if (data.data) setScript(data.data[0])
    else setScript(null)
  }, [db, debouncedSearchField])

  useEffect(() => {
    if (!script) {
      if (num > 0) {
        const s = scripts.find(x => x.number === num)
        if (s) {
          setScript(s)
          return
        }
      }
      if (scripts.length > 0) setScript(scripts[0])
      else setScript(null)
    }
  }, [num, script, scripts])

  const onScriptClicked = (scr: IScriptDetailItem) => {
    setScript(scr)
    navigate(`/script/${db}/${scr.number}`)
  }

  useEffect(() => {
    searchScripts()
  }, [searchScripts])

  return (
    <MainLayout>
      <MainContainer className='p-0'>
        <div className='script-grid'>
          <div className='script-header-list p-2'>
            <Row>
              <Col>
                <Dropdown>
                  <Dropdown.Toggle variant='primary' id='dropdown-basic'>
                    <>{scriptDbs[db - 1].name}</>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {scriptDbs.map(x => (
                      <Dropdown.Item onClick={() => setDb(x.id)}>{x.name}</Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
              {script && (
                <Col xs='auto'>
                  <Link className='btn btn-link bg-white me-1' to={`/Script/New/${db}`}>
                    <FaPlus />
                  </Link>
                  <Link
                    className='btn btn-link bg-white me-1'
                    to={`/Script/Update/${script.databaseId}/${script.number}`}
                  >
                    <FaRegEdit />
                  </Link>
                  <Link
                    className='btn btn-link bg-white me-2'
                    to='/markdown/synchro-db-scripts-guide.md'
                  >
                    <FaQuestionCircle />
                  </Link>
                  <Link className='btn btn-link bg-white me-2' to='/'>
                    <FaDownload />
                  </Link>
                </Col>
              )}
            </Row>
          </div>

          <div className='script-header-detail py-2 px-3'>
            <InputGroup>
              <FormControl
                placeholder='Hledat...'
                value={searchField}
                onChange={e => setSearchField(e.target.value)}
              />
              {searchField.length > 0 && (
                <Button
                  variant='outline-danger'
                  onClick={() => {
                    setSearchField('')
                  }}
                >
                  <FaTimes />
                </Button>
              )}
            </InputGroup>
          </div>
          <div className='script-list p-3'>
            {isLoadingScripts && <Loading />}
            {scripts && (
              <ScriptList
                scripts={scripts}
                selectedScript={script}
                onScriptClicked={onScriptClicked}
              />
            )}
          </div>
          {script && (
            <div className='script-detail p-3'>
              {!isLoadingScripts && scripts.length === 0 && <h3>Žádné skripty nenalezeny</h3>}
              {script !== undefined && <ScriptDetail script={script} />}
            </div>
          )}
        </div>
        {!isLoadingScripts && scripts.length === 0 && (
          <Alert variant='warning' className='c'>
            Žádné skripty nenalezeny
          </Alert>
        )}
      </MainContainer>
    </MainLayout>
  )
}

export default Script
