import axios from 'axios'
import { format, parseISO } from 'date-fns'
import { useCallback, useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { FaEdit } from 'react-icons/fa'
import { Link, useNavigate } from 'react-router-dom'
import BnfTable from '../../comps/bnfTable/BnfTable'
import IBnfTableDefColumn from '../../comps/bnfTable/IBnfTableDefColumn'
import MainContainer from '../../comps/MainContainer'
import { API_BASE_URL } from '../../consts'
import MainLayout from '../../layouts/MainLayout'
import IAdminQuery from '../../types/IAdminQuery'
import AdminQueryEditModal from '../AdminQueryDetail/AdminQueryEditModal'

const AdminQueryIndex = () => {
  const [queries, setQueries] = useState<IAdminQuery[] | null>(null)
  const [editModalVisible, setEditModalVisible] = useState<boolean>(false)
  const navigate = useNavigate()

  const loadQueries = useCallback(async () => {
    const result = await axios.get<IAdminQuery[]>(`${API_BASE_URL}/AdminQuery`)
    setQueries(result.data)
  }, [])

  const saveQuery = async (name: string, sql: string, archive: boolean) => {
    setEditModalVisible(false)
    if (sql) {
      await axios.post(`${API_BASE_URL}/AdminQuery/add`, {
        id: 0,
        name: name,
        sql: sql,
      })
    }
    navigate(0)
  }

  useEffect(() => {
    loadQueries()
  }, [loadQueries])

  const editValueFormat = ({ cell }: any) => {
    return (
      <div className='r'>
        <Link to={`/admin-query/${cell.value}`}>
          <FaEdit />
        </Link>
      </div>
    )
  }

  const databseTableDef: Array<IBnfTableDefColumn> = [
    {
      caption: ' ',
      fieldName: 'id',
      filterType: 'none',
      valueFormat: editValueFormat,
    },
    {
      caption: 'Název',
      fieldName: 'name',
      valueFormat: (cell: any) => {
        return (
          <span className={cell.row.values['archive'] ? 'text-line-through' : ''}>
            {cell.row.values['name']}
          </span>
        )
      },
    },
    {
      caption: ' a',
      fieldName: 'archive',
      filterType: 'none',
      isVisible: false,
    },
    {
      caption: 'Datum',
      fieldName: 'date',
      valueFormat: ({ cell: { value } }: any) => {
        return <>{value && format(parseISO(value), 'yyyy-MM-dd HH:ss')}</>
      },
    },
    {
      caption: 'Počet výsledků',
      fieldName: 'resultCount',
    },
  ]

  return (
    <MainLayout>
      <MainContainer>
        <div className=''>
          <Button variant='primary' size='sm' onClick={() => setEditModalVisible(true)}>
            Nový
          </Button>
        </div>
        {queries && <BnfTable source={queries} columnsDef={databseTableDef} />}
        <AdminQueryEditModal
          query={null}
          onCancel={() => setEditModalVisible(false)}
          onSubmit={(name, sql, archive) => saveQuery(name, sql, archive)}
          isVisible={editModalVisible}
        />
      </MainContainer>
    </MainLayout>
  )
}

export default AdminQueryIndex
