import axios from 'axios'
import { ChangeEvent, FormEvent, useCallback, useContext, useEffect, useState } from 'react'
import { Button, Col, Form, FormGroup, Row } from 'react-bootstrap'
import { useParams } from 'react-router'
import Loading from '../../comps/Loading'
import MainContainer from '../../comps/MainContainer'
import { API_BASE_URL } from '../../consts'
import MainLayout from '../../layouts/MainLayout'
import IScriptDetailItem from '../../types/IScriptDetailItem'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext'
import Avatar from '../../comps/Avatar'
import ScriptContext from '../../contexts/ScriptContext'

const ScriptEdit = () => {
  const [isLoadingScript, setIsLoadingScript] = useState<boolean>(false)
  const [num] = useState<number>(parseInt(useParams()?.num ?? '0'))
  const [db] = useState<number>(parseInt(useParams()?.db ?? '1'))
  const [script, setScript] = useState<IScriptDetailItem | null>(null)
  const { scriptDbs } = useContext(ScriptContext)
  const { user } = useAuth()
  const navigate = useNavigate()

  const loadScript = useCallback(async () => {
    if (num > 0) {
      setIsLoadingScript(true)
      const result = await axios.get<IScriptDetailItem>(
        `${API_BASE_URL}/Script/Get/${num}?db=${db}`
      )
      setScript(result.data)
      setIsLoadingScript(false)
    }
  }, [num, db])

  const saveScript = async () => {
    if (!script || !user) return

    try {
      setIsLoadingScript(true)
      await axios.post(`${API_BASE_URL}/Script/Modify?id=${script.id}`, {
        db: 0,
        userName: user.username,
        name: script.name,
        sql: script.sql,
        isBreakingChange: script.isBreakingChange,
      })
    } finally {
      setIsLoadingScript(false)
    }
    navigate(`/Script/${db}`)
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
    if (script) {
      const name = e.target.name
      const value = e.target.value
      setScript({ ...script, [name]: value })
    }
  }

  const toggleBreakingCheck = () => {
    if (script) setScript({ ...script, isBreakingChange: !script.isBreakingChange })
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!script || !user) return

    if (!validateForm()) {
      alert('Vyplňte všechny údaje o skriptu')
      return
    }

    saveScript()
  }

  const validateForm = (): boolean => {
    if (!script || !user) return false
    if (script.name === '' || script.sql === '') return false

    return true
  }

  useEffect(() => {
    if (db && num) loadScript()
  }, [loadScript, num, db])

  return (
    <MainLayout>
      <MainContainer className='mw-1200 p-3'>
        <div className='pt-3 pb-3'>
          {isLoadingScript && <Loading />}
          {script && (
            <>
              <Row>
                <Col>
                  <h3 className='mb-3'>{db && num ? <>#{script.number} </> : 'Nový skript'}</h3>
                </Col>
                <Col sm='auto'>
                  <span className='me-3'>DB: {scriptDbs[db - 1].name}</span>
                  <Avatar initials={script.userInitials} isInternal={true} />
                </Col>
              </Row>
              <Form onSubmit={e => handleSubmit(e)}>
                <FormGroup as={Row} className='mb-3'>
                  <Form.Label column sm={2}>
                    Breaking Change
                  </Form.Label>
                  <div className='col-auto d-flex flex-column justify-content-center'>
                    <input
                      type='checkbox'
                      className='form-check-input'
                      id='breakingCheck'
                      name='isBreakingChange'
                      defaultChecked={script.isBreakingChange}
                      onChange={toggleBreakingCheck}
                    />
                  </div>
                </FormGroup>
                <FormGroup as={Row} className='mb-3'>
                  <Form.Label column sm={2}>
                    Název
                  </Form.Label>
                  <Col sm={10}>
                    <input
                      type='text'
                      className='form-control'
                      id='inputTitle'
                      placeholder='Název...'
                      name='name'
                      value={script.name}
                      onChange={e => handleChange(e)}
                    />
                  </Col>
                </FormGroup>
                <FormGroup as={Row} className='mb-3'>
                  <Form.Label column sm={2}>
                    Sql
                  </Form.Label>
                  <Col sm={10}>
                    <textarea
                      style={{ minHeight: '30vh', height: '50%' }}
                      className='form-control'
                      id='inputSql'
                      placeholder='Sql...'
                      name='sql'
                      value={script.sql}
                      onChange={e => handleChange(e)}
                    />
                  </Col>
                </FormGroup>
                <Row>
                  <Col>
                    <Button type='submit' variant='primary'>
                      Uložit změnu
                    </Button>
                  </Col>
                </Row>
              </Form>
            </>
          )}
        </div>
      </MainContainer>
    </MainLayout>
  )
}

export default ScriptEdit
