import './Loading.scss'

const Loading = () => {
  return (
    <div className='loading'>
      <div className='loader'></div>
    </div>
  )
}

export default Loading
