import axios from 'axios'
import { useCallback, useContext, useEffect, useState } from 'react'
import MainContainer from '../../comps/MainContainer'
import { API_BASE_URL } from '../../consts'
import MainLayout from '../../layouts/MainLayout'
import { ISiteInfo } from '../../types/ISiteInfo'
import moment from 'moment'
import IBnfTableDefColumn from '../../comps/bnfTable/IBnfTableDefColumn'
import BnfTable from '../../comps/bnfTable/BnfTable'
import { FaCircle, FaEdit } from 'react-icons/fa'
import WebOnlineContext from '../../contexts/WebOnlineContext'
import { Link } from 'react-router-dom'

type Props = { onlyOffline?: boolean }

const Sites = ({ onlyOffline }: Props) => {
  const [sites, setSites] = useState<ISiteInfo[]>([])
  const { webOnlineMonitors } = useContext(WebOnlineContext)
  const offlineIds = webOnlineMonitors?.monitors.filter(f => f.status !== 2).map(f => f.id)

  const getColorClass = (eid: number) => {
    const onlineColorClass: { [key: number]: string } = {
      9: 'text-danger',
      2: 'text-success',
      99: 'text-muted',
    }

    if (webOnlineMonitors) {
      var m = webOnlineMonitors.monitors.find(x => x.id === eid)
      if (m) return onlineColorClass[m.status]
    }

    return onlineColorClass[99]
  }

  const loadSites = useCallback(async () => {
    const data = await axios.get<ISiteInfo[]>(`${API_BASE_URL}/Site`)
    const res = data.data
    setSites(res)
  }, [])

  useEffect(() => {
    loadSites()
  }, [loadSites])

  const siteTableDef: Array<IBnfTableDefColumn> = [
    {
      caption: '',
      fieldName: 'id',
      filterType: 'none',
      valueFormat: ({ cell: { value } }: any) => {
        return (
          <>
            <Link to={`/site/${value}`}>
              <FaEdit />
            </Link>
          </>
        )
      },
    },
    {
      caption: 'Url',
      fieldName: 'url',
      valueFormat: ({ cell: { value } }: any) => {
        return (
          <a target='_blank' href={value} rel='noreferrer'>
            {value}
          </a>
        )
      },
    },
    {
      caption: '',
      fieldName: 'onlineStatus',
      filterType: 'none',
      valueFormat: (cell: any) => {
        return (
          <>
            <FaCircle className={getColorClass(cell.row.values['externalId'])} />
          </>
        )
      },
    },
    {
      caption: 'Type',
      fieldName: 'typeName',
      filterType: 'select',
    },
    {
      caption: 'Partner',
      fieldName: 'partner',
      filterType: 'select',
    },
    {
      caption: 'Orders year',
      fieldName: 'orderCount365',
      filterType: 'none',
      sortType: 'num',
    },
    {
      caption: 'Orders week',
      fieldName: 'orderCount7',
      filterType: 'none',
      sortType: 'num',
    },
    {
      caption: 'Orders day',
      fieldName: 'orderCount24',
      filterType: 'none',
      sortType: 'num',
    },
    {
      caption: 'XXX',
      fieldName: 'lastPublishUser',
      isVisible: false,
      filterType: 'none',
    },
    {
      caption: 'External ID',
      fieldName: 'externalId',
      sortType: 'num',
      valueFormat: ({ cell: { value } }: any) => {
        return (
          <>
            {value > 0 && (
              <a
                target='_blank'
                href={'https://uptimerobot.com/dashboard#' + value}
                rel='noreferrer'
              >
                {value}
              </a>
            )}
          </>
        )
      },
    },
    {
      caption: 'Last published',
      fieldName: 'lastPublishDate',
      valueFormat: (cell: any) => {
        return (
          <>
            {cell.value !== null && (
              <span className='text-nowrap'>
                {moment(new Date(cell.value)).fromNow()} [{cell.row.values['lastPublishUser']}]
              </span>
            )}
          </>
        )
      },
    },
  ]

  return (
    <MainLayout>
      <MainContainer>
        {sites && (
          <div className='mt-3'>
            <BnfTable
              source={
                onlyOffline ? sites.filter(x => offlineIds?.find(f => f === x.externalId)) : sites
              }
              columnsDef={siteTableDef}
            />
          </div>
        )}
      </MainContainer>
    </MainLayout>
  )
}

export default Sites
