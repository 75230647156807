import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import './Avatar.scss'

type AvatarProps = {
  variant?: string
  initials: string
  tooltip?: string
  isInternal?: boolean
  isClosed?: boolean
}

const Avatar = ({ initials, tooltip, isInternal, isClosed, variant = 'default' }: AvatarProps) => {
  const bg = isClosed ? 'bg-closed' : isInternal ? 'bg-avatar-internal' : 'bg-avatar-external'
  return (
    <>
      {tooltip ? (
        <OverlayTrigger
          placement='bottom'
          overlay={
            <Tooltip id='overlay-example'>
              {tooltip && <div dangerouslySetInnerHTML={{ __html: tooltip }} />}
            </Tooltip>
          }
        >
          <span className={`avatar avatar-${variant} ${bg}`}>
            <span className='avatar-inis'>{initials}</span>
          </span>
        </OverlayTrigger>
      ) : (
        <span className={`avatar avatar-${variant} ${bg}`}>
          <span className='avatar-inis'>{initials}</span>
        </span>
      )}
    </>
  )
}

export default Avatar
