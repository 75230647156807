import React from 'react'
import MainContainer from '../../comps/MainContainer'
import MainLayout from '../../layouts/MainLayout'
import ToolCard from './comps/ToolCard'
import IToolItem from '../../types/IToolItem'
import { FaDatabase, FaLock } from 'react-icons/fa'
import { useNavigate } from 'react-router'
import { Card, Col, Row } from 'react-bootstrap'

const onlineTools: IToolItem[] = [
  {
    name: 'Script Guide',
    description:
      'Pomocník pro psaní SQL skriptů - pomůže s ošetřením základních konstrukcí typu: nová tabulka, nový sloupec apod.',
    icon: <FaDatabase />,
    path: '/Tools/ScriptGuide',
  },
  {
    name: 'Enc-Dec',
    description:
      'Šifrovací/dešifrovací utilitka rychlé vytvoření zašifrované hodnoty nebo její dešifrování. Algoritmus je používán v našich .NET projektech.',
    icon: <FaLock />,
    path: '/Tools/EncDec',
  },
]

const Tools = () => {
  const navigate = useNavigate()

  return (
    <MainLayout>
      <MainContainer className='mw-1200 p-3 pt-5'>
        <Row>
          <Col>
            <h4 className='mb-3'>Nástroje on-line</h4>
            {onlineTools.map((tool, index) => {
              return (
                <div className='mb-3' key={index}>
                  <ToolCard tool={tool} handleClick={() => navigate(tool.path)} />
                </div>
              )
            })}
          </Col>
          <Col>
            <h4 className='mb-3'>Nástroje ke stažení</h4>
            <div className='mb-3'>
              <Card>
                <Card.Header>
                  <h6 className='title'>
                    <FaDatabase />
                    <span> bnf-db</span>
                  </h6>
                </Card.Header>
                <Card.Body>
                  <Card.Text>
                    Klient pro synchronizaci databáze. Návod pro instalaci a použití je na
                    <a
                      href='https://github.com/BenefitCZ/support-kb/blob/main/DB/Synchronizace/bnf-db.md'
                      target='_blank'
                      rel='noreferrer'
                    >
                      &nbsp;GitHub
                    </a>
                    <span>.</span>
                    <div>
                      <ul>
                        <li>
                          <a href='https://download.benefitcz.cz/bnf-db/bnf-db-framework-depend.zip'>
                            Light verze
                          </a>
                        </li>
                        <li>
                          <a href='https://download.benefitcz.cz/bnf-db/bnf-db-standalone.zip'>
                            Standalone verze
                          </a>
                        </li>
                      </ul>
                    </div>
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
          </Col>
          <Col>
            <h4 className='mb-3'>Deployment</h4>
            <div className='mb-3'>
              <Card>
                <Card.Header>
                  <h6 className='title'>
                    <FaDatabase />
                    <span> bnf-deploy</span>
                  </h6>
                </Card.Header>
                <Card.Body>
                  <Card.Text>
                    Tool pro deployment webů B2B, B2C a Admin. Projekty, které lze takto publikovat
                    musí být nejprve registrovány v devzone
                    <div>
                      <ul>
                        <li>
                          <a href='https://download.benefitcz.cz/bnf-deploy/bnf-deploy.zip'>
                            bnf-deploy
                          </a>
                        </li>
                      </ul>
                    </div>
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
          </Col>
        </Row>
      </MainContainer>
    </MainLayout>
  )
}

export default Tools
