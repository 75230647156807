import axios from 'axios'
import './KbIndex.scss'
import { useCallback, useEffect, useState } from 'react'
import { Badge, Button, Col, Form, InputGroup, ListGroup, Row } from 'react-bootstrap'
import Loading from '../../comps/Loading'
import MainContainer from '../../comps/MainContainer'
import { API_BASE_URL } from '../../consts'
import MainLayout from '../../layouts/MainLayout'
import IKbTag from '../../types/IKbTag'
import IKbText from '../../types/IKbText'
import { FaSearch } from 'react-icons/fa'
import { useDebounce } from 'use-debounce'
import MarkdownPreview from '@uiw/react-markdown-preview'
import Select, { ActionMeta, OnChangeValue } from 'react-select'
import { Editor } from '@tinymce/tinymce-react'

const KbIndex = () => {
  const [search, setSearch] = useState<string>('')
  const [debouncedSearch] = useDebounce(search, 1000)
  const [tags, setTags] = useState<IKbTag[] | null>()
  const [selectedTags, setSelectedTags] = useState<IKbTag[] | null>(null)
  const [items, setItems] = useState<IKbText[] | null>(null)
  const [selectedItem, setSelectedItem] = useState<IKbText | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [editMode, setEditMode] = useState<boolean>(false)

  const loadTags = useCallback(async () => {
    const data = await axios.get<IKbTag[]>(`${API_BASE_URL}/Kb/tags`)
    setTags([{ value: -1, label: 'Vše' }, ...data.data])
  }, [])

  const loadItems = useCallback(async () => {
    try {
      setLoading(true)
      const t =
        selectedTags
          ?.map(x => x.value)
          .filter(x => x > 0)
          .join(',') ?? ''
      const data = await axios.get<IKbText[]>(
        `${API_BASE_URL}/Kb?tags=${t}&search=${debouncedSearch}`
      )
      setItems(data.data)
      setSelectedItem(data.data[0])
    } finally {
      setLoading(false)
    }
  }, [selectedTags, debouncedSearch])

  const onTagSelectionChange = (
    newValue: OnChangeValue<IKbTag, true>,
    actionMeta: ActionMeta<any>
  ) => {
    setSelectedTags(newValue as IKbTag[])
  }

  const save = async () => {
    if (selectedItem && items) {
      var res = await axios.post<IKbText>(`${API_BASE_URL}/Kb/add`, selectedItem)

      setEditMode(false)

      setSelectedItem(res.data)
      setItems([selectedItem, ...items])
    }
  }

  const update = async () => {
    if (selectedItem && items) {
      await axios.post(`${API_BASE_URL}/Kb/update`, selectedItem)

      setEditMode(false)

      const item = items.find(x => x.id === selectedItem.id)
      if (item) {
        item.text = selectedItem.text
        item.name = selectedItem.name
      }
    }
  }

  const cancel = () => {
    if (selectedItem && items) {
      setEditMode(false)
      if (selectedItem.id) setSelectedItem(items.find(x => x.id === selectedItem.id) ?? null)
      else setSelectedItem(items[0])
    }
  }

  const newKb = () => {
    setEditMode(true)
    setSelectedItem({ id: 0, name: '', format: 1, text: '', date: '', tags: [] })
  }

  useEffect(() => {
    loadTags()
  }, [loadTags])

  useEffect(() => {
    loadItems()
  }, [loadItems])

  return (
    <MainLayout>
      <MainContainer className='kb p-3'>
        {loading && <Loading />}
        {!loading && tags && items && (
          <>
            <div className='kb-grid'>
              <div className='kb-filter'>
                <InputGroup className='mb-1'>
                  <Form.Control
                    autoFocus={true}
                    placeholder='Vyhledat ...'
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                  />
                  <Button variant='primary' onClick={() => loadItems()}>
                    <FaSearch />
                  </Button>
                </InputGroup>
                <Select
                  isMulti
                  isClearable
                  placeholder='Tagy ...'
                  options={tags}
                  onChange={(newValue, action) => onTagSelectionChange(newValue, action)}
                  value={selectedTags}
                  className='react-select-container'
                  classNamePrefix='react-select'
                />
              </div>
              <div className='kb-title '>
                <div className='h-100 d-flex flex-column justify-content-end'>
                  <Row>
                    <Col>
                      {selectedItem && !editMode && (
                        <div>
                          {selectedItem.tags.map(t => (
                            <Badge bg='secondary' className='me-1'>
                              {t.label}
                            </Badge>
                          ))}
                        </div>
                      )}
                    </Col>
                  </Row>
                  {selectedItem && editMode && (
                    <Row>
                      <Col>
                        <Select
                          isMulti
                          isClearable={false}
                          placeholder='Tagy ...'
                          options={tags.filter(x => x.value > 0)}
                          value={selectedItem.tags}
                          onChange={newValue => {
                            setSelectedItem({
                              ...selectedItem,
                              tags: (selectedItem.tags = newValue as IKbTag[]),
                            })
                          }}
                          className='react-select-container'
                          classNamePrefix='react-select'
                        />
                      </Col>
                      <Col xs='auto'>
                        <span className='me-2'>HTML</span>
                        <Form.Check
                          type='switch'
                          id='custom-switch'
                          label='Markdown'
                          className='d-inline-block'
                          checked={selectedItem?.format === 1}
                          onChange={e =>
                            setSelectedItem({ ...selectedItem, format: e.target.checked ? 1 : 0 })
                          }
                        />
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col className='d-flex justify-content-end flex-column'>
                      {!editMode && selectedItem && <h5 className='mb-0'>{selectedItem.name}</h5>}
                      {editMode && selectedItem && (
                        <input
                          type='text'
                          placeholder='název příspěvku'
                          value={selectedItem.name}
                          className='form-control'
                          onChange={e => setSelectedItem({ ...selectedItem, name: e.target.value })}
                        />
                      )}
                    </Col>
                    <Col className='r' xs='auto'>
                      {!editMode && selectedItem && (
                        <>
                          <Button
                            variant='secondary'
                            onClick={() => setEditMode(!editMode)}
                            size='sm'
                          >
                            Upravit
                          </Button>
                          <Button
                            variant='primary'
                            onClick={() => newKb()}
                            size='sm'
                            className='ms-1'
                          >
                            Nový
                          </Button>
                        </>
                      )}
                      {editMode && selectedItem && (
                        <>
                          <Button
                            className='me-1'
                            variant='outline-secondary'
                            onClick={() => cancel()}
                            size='sm'
                          >
                            Storno
                          </Button>
                          <Button
                            variant='success'
                            onClick={() => (selectedItem.id > 0 ? update() : save())}
                            size='sm'
                          >
                            Uložit
                          </Button>
                        </>
                      )}
                    </Col>
                  </Row>
                </div>
              </div>
              <div className='kb-index'>
                {items.map(i => (
                  <ListGroup>
                    <ListGroup.Item
                      action
                      onClick={() => {
                        if (editMode) cancel()
                        setSelectedItem(i)
                      }}
                      active={selectedItem?.id === i.id}
                    >
                      {i.name}
                    </ListGroup.Item>
                  </ListGroup>
                ))}
              </div>
              <div className='kb-text'>
                {selectedItem && !editMode && (
                  <>
                    {selectedItem.format === 0 && (
                      <div dangerouslySetInnerHTML={{ __html: selectedItem.text }}></div>
                    )}
                    {selectedItem.format === 1 && (
                      <MarkdownPreview
                        source={selectedItem.text}
                        className='bg-white text-dark'
                      ></MarkdownPreview>
                    )}
                  </>
                )}
                {selectedItem && editMode && (
                  <>
                    {selectedItem.format === 0 && (
                      <Editor
                        value={selectedItem.text}
                        init={{
                          plugins: 'link lists',
                          content_css: false,
                          menubar: false,
                          statusbar: false,
                          paste_data_images: false,
                          language: 'cs',
                          height: '100%',
                          toolbar: `undo redo | bold italic underline removeformat |bullist numlist |link |alignleft aligncenter alignright alignjustify`,
                        }}
                        onEditorChange={e => {
                          setSelectedItem({ ...selectedItem, text: e })
                        }}
                        apiKey='swex3rauswssx6520dp44fhft9ws980djqojd3v90vmnq2gf'
                      ></Editor>
                    )}
                    {selectedItem.format === 1 && (
                      <textarea
                        className='form-control h-100'
                        value={selectedItem.text}
                        onChange={e => setSelectedItem({ ...selectedItem, text: e.target.value })}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          </>
        )}
      </MainContainer>
    </MainLayout>
  )
}

export default KbIndex
