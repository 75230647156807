import React, { useMemo } from 'react'
import { Form } from 'react-bootstrap'
import { FaCaretDown, FaCaretUp } from 'react-icons/fa'
import { Column, HeaderGroup, Row, useFilters, useSortBy, useTable } from 'react-table'
import './BnfTable.scss'
import IBnfTableDefColumn from './IBnfTableDefColumn'

type Props = {
  source: Array<any>
  columnsDef: Array<IBnfTableDefColumn>
}

type FilterProps = {
  column: HeaderGroup
}

// Define a default UI for filtering
const DefaultColumnFilter = ({ column }: FilterProps) => {
  return (
    <Form.Control
      className='filter'
      size='sm'
      type='text'
      placeholder=''
      value={column.filterValue || ''}
      onChange={e => {
        column.setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
      }}
    />
  )
}

const SelectColumnFilter = ({ column }: FilterProps) => {
  const options = useMemo<string[]>(() => {
    const options = new Set<string>()
    column.preFilteredRows.forEach(row => {
      options.add(row.values[column.id])
    })
    return [...options.values()]
  }, [column.id, column.preFilteredRows])

  return (
    <Form.Select
      className='filter'
      size='sm'
      value={column.filterValue}
      onChange={e => column.setFilter(e.target.value || undefined)}
    >
      <option value=''>Vše</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </Form.Select>
  )
}

const defaultSortFunction = (rowA: Row, rowB: Row, columnId: string, desc: boolean) => {
  const cellA = rowA.values[columnId]?.toLowerCase()
  const cellB = rowB.values[columnId]?.toLowerCase()

  if (cellA > cellB) return 1
  if (cellA < cellB) return -1
  return 0
}

const defaultNumbersSortFunction = (rowA: Row, rowB: Row, columnId: string, desc: boolean) => {
  const cellA = Number(rowA.values[columnId])
  const cellB = Number(rowB.values[columnId])

  if (cellA > cellB) return 1
  if (cellA < cellB) return -1
  return 0
}

const defaultValueFormat = ({ cell: { value } }: any) => {
  return <>{value}</>
}

const BnfTable = ({ source, columnsDef }: Props) => {
  const defaultSortType = useMemo<any>(() => defaultSortFunction, [])
  const defaultNumbersSortType = useMemo<any>(() => defaultNumbersSortFunction, [])
  const projectsData = useMemo<Array<any>>(() => source, [source])
  const columns = useMemo<Array<Column>>(
    () =>
      columnsDef.map(x => {
        return {
          accessor: x.fieldName,
          Header: x.caption,
          disableFilters: x.filterType === 'none',
          Filter: x.filterType === 'select' ? SelectColumnFilter : DefaultColumnFilter,
          sortType: x.sortType === 'num' ? defaultNumbersSortType : defaultSortType,
          disableSortBy: x.sortType === 'none',
          Cell: x.valueFormat ?? defaultValueFormat,
          isVisible: x.isVisible,
        }
      }),
    [defaultSortType, defaultNumbersSortType, columnsDef]
  )

  const defaultColumn = React.useMemo<any>(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  )

  const hiddenCol: string[] = columnsDef.filter(x => !(x.isVisible ?? true)).map(x => x.fieldName)

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data: projectsData,
      defaultColumn,
      initialState: {
        hiddenColumns: hiddenCol,
      },
    },
    useFilters,
    useSortBy
  )

  return (
    <section id='bnf-table-wrapper'>
      <table {...getTableProps()} className='table table-striped'>
        {headerGroups.map((group, index) => {
          return (
            <thead className='table-light' key={index}>
              <tr {...group.getHeaderGroupProps()} className='bnf-table-captions'>
                {group.headers.map(column => {
                  return (
                    <th key={column.id}>
                      <div {...column.getHeaderProps(column.getSortByToggleProps())}>
                        {column.render('Header')}
                        <span>
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <FaCaretDown />
                            ) : (
                              <FaCaretUp />
                            )
                          ) : (
                            ''
                          )}
                        </span>
                      </div>
                    </th>
                  )
                })}
              </tr>
              <tr className='bnf-table-filters'>
                {group.headers.map(column => {
                  return <th key={column.id}>{column.canFilter && column.render('Filter')}</th>
                })}
              </tr>
            </thead>
          )
        })}
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()} key={row.id}>
                {row.cells.map(cell => {
                  return (
                    <td {...cell.getCellProps()} key={cell.column.id}>
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </section>
  )
}

export default BnfTable
