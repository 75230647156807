import { useState } from 'react'
import IScriptDb from '../types/IScriptDb'
import ScriptContext from './ScriptContext'

type Props = {
  children: React.ReactNode
}

const ScriptContextProvider = ({ children }: Props) => {
  const [scriptDbs] = useState<IScriptDb[]>([
    { id: 1, name: 'Benefit' },
    { id: 2, name: 'Pasport' },
    { id: 3, name: 'Doprava' },
  ])

  return (
    <ScriptContext.Provider
      value={{
        scriptDbs,
      }}
    >
      {children}
    </ScriptContext.Provider>
  )
}

export default ScriptContextProvider
