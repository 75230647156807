import { useContext } from 'react'
import { Badge } from 'react-bootstrap'
import { FaSitemap } from 'react-icons/fa'
import WebOnlineContext from '../contexts/WebOnlineContext'

const WebOfflineIcon = () => {
  const { webOnlineMonitors } = useContext(WebOnlineContext)
  const count = webOnlineMonitors?.monitors.filter(x => x.status === 9).length ?? 0

  return (
    <>
      {webOnlineMonitors && (
        <>
          <FaSitemap className='icon' />
          <Badge pill bg={count > 0 ? 'danger' : 'success'} className='badge-top-index'>
            {count}
          </Badge>
        </>
      )}
    </>
  )
}

export default WebOfflineIcon
