import { createRoot } from 'react-dom/client'
import App from './App'
import 'bootstrap/dist/css/bootstrap.css'
import './index.scss'
import { MsalProvider } from '@azure/msal-react'
import { PublicClientApplication } from '@azure/msal-browser'
import { msalConfig } from './authUtils'

const msalInstance = new PublicClientApplication(msalConfig)

const container = document.getElementById('root')
const root = createRoot(container!)

root.render(
  <MsalProvider instance={msalInstance}>
    <App />
  </MsalProvider>
)
