import axios from 'axios'
import { useEffect, useState } from 'react'
import { API_BASE_URL, API_UPTIME_BASE_URL } from '../consts'
import { IBatchResult } from '../types/IBatchResult'
import IUptimeData from '../types/IUptimeData'
import WebOnlineContext from './WebOnlineContext'

type Props = {
  children: React.ReactNode
}

const WebOnlineContextProvider = ({ children }: Props) => {
  const [webOnlineMonitors, setWebOnlineMonitors] = useState<IUptimeData | null>(null)
  const [batchErrors, setBatchErrors] = useState<IBatchResult[] | null>(null)

  const loadUptimeData = async () => {
    const result = await axios.get<IUptimeData>(`${API_UPTIME_BASE_URL}/UpTime`)
    setWebOnlineMonitors(result.data)
  }

  const loadBatchErrors = async () => {
    const result = await axios.get<IBatchResult[]>(`${API_BASE_URL}/Batch/get-errors`)
    setBatchErrors(result.data)
  }

  useEffect(() => {
    loadUptimeData()

    const interval = setInterval(() => {
      loadUptimeData()
    }, 60000)

    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    loadBatchErrors()
  }, [])

  return (
    <WebOnlineContext.Provider
      value={{
        webOnlineMonitors,
        batchErrors,
      }}
    >
      {children}
    </WebOnlineContext.Provider>
  )
}

export default WebOnlineContextProvider
