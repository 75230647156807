import React, { useState } from 'react'
import MainContainer from '../../../comps/MainContainer'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { materialLight as Style } from 'react-syntax-highlighter/dist/esm/styles/prism'
import './ScriptGuide.scss'
import MainLayout from '../../../layouts/MainLayout'

const ScriptGuide = () => {
  const [newTableTableName, setNewTableTableName] = useState('')
  const [newFieldTableName, setNewFieldTableName] = useState('')
  const [newFieldFieldName, setNewFieldFieldName] = useState('')
  const [newFieldFieldType, setNewFieldFieldType] = useState('')

  return (
    <MainLayout>
      <MainContainer className='mw-1200 p-3'>
        <section id='scriptguide-wrapper'>
          <h3 className='pb-3 pt-3'>Script Guide</h3>

          <h4 className='pb-2'>Nová tabulka</h4>
          <input
            type='text'
            className='form-control search-field'
            placeholder='#TABLE-NAME'
            value={newTableTableName}
            onChange={e => setNewTableTableName(e.target.value)}
            style={{ width: '10rem' }}
          />
          <SyntaxHighlighter language='sql' style={Style}>
            {`if not exists (select * from dbo.sysobjects where id = object_id(N'[dbo].[${
              newTableTableName !== '' ? newTableTableName : '#TABLE-NAME'
            }]') )
CREATE TABLE[dbo].[${newTableTableName !== '' ? newTableTableName : '#TABLE-NAME'}](
...zde definice položek
)
GO`}
          </SyntaxHighlighter>

          <h4 className='pt-3 pb-2'>Nová položka do existující tabulky</h4>
          <div className='d-flex flex-row'>
            <input
              type='text'
              className='form-control search-field'
              placeholder='#TABLE-NAME'
              value={newFieldTableName}
              onChange={e => setNewFieldTableName(e.target.value)}
              style={{ width: '10rem' }}
            />
            <input
              type='text'
              className='form-control search-field'
              placeholder='#FIELD-NAME'
              value={newFieldFieldName}
              onChange={e => setNewFieldFieldName(e.target.value)}
              style={{ width: '10rem' }}
            />
            <input
              type='text'
              className='form-control search-field'
              placeholder='#FIELD-TYPE'
              value={newFieldFieldType}
              onChange={e => setNewFieldFieldType(e.target.value)}
              style={{ width: '10rem' }}
            />
          </div>
          <SyntaxHighlighter language='sql' style={Style}>
            {`if not exists (select * from dbo.syscolumns where id = object_id('dbo.${
              newFieldTableName !== '' ? newFieldTableName : '#TABLE-NAME'
            }') and name = '${newFieldFieldName !== '' ? newFieldFieldName : '#FIELD-NAME'}')
alter table [dbo].[${newFieldTableName !== '' ? newFieldTableName : '#TABLE-NAME'}] add ${
              newFieldFieldName !== '' ? newFieldFieldName : '#FIELD-NAME'
            } ${newFieldFieldType !== '' ? newFieldFieldType : '#FIELD-TYPE'}
go`}
          </SyntaxHighlighter>
        </section>
      </MainContainer>
    </MainLayout>
  )
}

export default ScriptGuide
