import { AccountInfo } from '@azure/msal-browser'
import { useMsal } from '@azure/msal-react'
import React, { useContext } from 'react'

type IAuthContext = {
  user: AccountInfo | undefined
}

const AuthContext = React.createContext<IAuthContext>({
  user: undefined,
})

export function useAuth() {
  return useContext(AuthContext)
}

type Props = {
  children: any
}

export function AuthProvider({ children }: Props) {
  const { accounts } = useMsal()

  const value = {
    user: accounts.length > 0 ? accounts[0] : undefined,
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
