import React, { useContext } from 'react'
import { Toast, ToastContainer } from 'react-bootstrap'
import NotificationsContext from '../../contexts/NotificationsContext'
import { NotificationType } from '../../types/NotificationModel'
import './ToastNotifications.scss'

const ToastNotifications = () => {
  const { notifications, removeNotification } = useContext(NotificationsContext)

  const getBg = (type: NotificationType): string => {
    switch (type) {
      case NotificationType.Danger:
        return 'danger'
      case NotificationType.Success:
        return 'success'
      case NotificationType.Warning:
        return 'warning'
      default:
        return ''
    }
  }

  return (
    <div id='toast-notification-wrapper'>
      <ToastContainer position='bottom-end' className='bottom-container'>
        {notifications.map(n => {
          return (
            <div className='toast-wrapper' key={n.id}>
              <Toast
                onClose={() => removeNotification(n.id)}
                className='fade-top'
                bg={getBg(n.type)}
              >
                <Toast.Header>
                  <strong className='me-auto'>{n.name}</strong>
                </Toast.Header>
                <Toast.Body className='mb-3'>{n.text}</Toast.Body>
              </Toast>
            </div>
          )
        })}
      </ToastContainer>
    </div>
  )
}

export default ToastNotifications
