import { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import IAdminQuery from '../../types/IAdminQuery'

type Props = {
  isVisible: boolean
  onCancel: () => void
  onSubmit: (name: string, sql: string, archive: boolean) => void
  query: IAdminQuery | null
}

const AdminQueryEditModal = ({ isVisible, onCancel, onSubmit, query }: Props) => {
  const [name, setName] = useState<string>('')
  const [sql, setSql] = useState<string>('')
  const [archive, setArchive] = useState<boolean>(false)

  useEffect(() => {
    if (query) {
      setName(query.name)
      setSql(query.sql)
      setArchive(query.archive)
    }
  }, [query])

  return (
    <>
      <Modal id='batch-modal' centered size='xl' show={isVisible} onHide={onCancel} scrollable>
        <Modal.Header closeButton>
          <Modal.Title>{query?.name ?? 'Nové query'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {query && (
            <div>
              <label className='me-2 mb-2'>disabled</label>
              <input
                type='checkbox'
                checked={archive}
                onChange={() => {
                  setArchive(!archive)
                }}
              />
            </div>
          )}
          <div className='mb-2'>
            <input
              type='text'
              value={name}
              className='form-control'
              placeholder='Název query'
              onChange={e => setName(e.target.value)}
            />
          </div>
          <textarea
            value={sql}
            className='form-control'
            placeholder='SQL'
            rows={15}
            onChange={e => setSql(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => onCancel()}>
            Storno
          </Button>
          <Button variant='primary' onClick={() => onSubmit(name, sql, archive)}>
            Uložit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default AdminQueryEditModal
