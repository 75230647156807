import React from 'react'
import { Button, Card } from 'react-bootstrap'
import { FaAngleRight } from 'react-icons/fa'
import IToolItem from '../../../types/IToolItem'
import './ToolCard.scss'

type Props = {
  handleClick?: Function
  tool: IToolItem
}

const ToolCard = ({ handleClick, tool }: Props) => {
  return (
    <Card id='card'>
      <Card.Header>
        <h6 className='title'>
          {tool.icon}
          <span> {tool.name}</span>
        </h6>
      </Card.Header>
      <Card.Body>
        <Card.Text>{tool.description}</Card.Text>
        <div className='r'>
          <Button
            variant='primary'
            size='sm'
            onClick={() => handleClick !== undefined && handleClick()}
          >
            {tool.name}{' '}
            <span>
              <FaAngleRight />
            </span>
          </Button>
        </div>
      </Card.Body>
    </Card>
  )
}

export default ToolCard
