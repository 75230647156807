import { createContext } from 'react'
import { NotificationModel } from '../types/NotificationModel'

interface INotificationsContext {
  notifications: NotificationModel[]
  clearNotifications: () => void
  addNotification: (notification: NotificationModel) => void
  removeNotification: (id?: number) => void
}

const NotificationsContext = createContext<INotificationsContext>({
  notifications: [],
  clearNotifications: () => undefined,
  addNotification: () => undefined,
  removeNotification: () => undefined,
})

export default NotificationsContext
