import { BrowserRouter, Routes, Route } from 'react-router-dom'
import PrivateRoute from '../comps/PrivateRoute'
import Home from '../pages/Home/Home'
import Script from '../pages/Script/Script'
import ScriptEdit from '../pages/Script/ScriptEdit'
import EncDec from '../pages/Tools/EncDec/EncDec'
import ScriptGuide from '../pages/Tools/ScriptGuide/ScriptGuide'
import Tools from '../pages/Tools/Tools'
import Sites from '../pages/Sites/Sites'
import Databases from '../pages/Databases/Databases'
import AdminQueryIndex from '../pages/AdminQueryIndex/AdminQueryIndex'
import AdminQueryDetail from '../pages/AdminQueryDetail/AdminQueryDetail'
import ScriptNew from '../pages/Script/ScriptNew'
import SiteDetail from '../pages/SiteDetail/SiteDetail'
import KbIndex from '../pages/KbIndex/KbIndex'
import { NotificationModel, NotificationType } from '../types/NotificationModel'
import axios from 'axios'
import ErrorResponse from '../types/ErrorResponse'
import MarkDownViewer from '../pages/MarkDown/MarkDownViewer'

const axiosContext = { addNotification: (notification: NotificationModel) => null as any }

axios.interceptors.response.use(
  response => {
    return response
  },
  error => {
    if (error.response) {
      const errorResponse = error.response.data as ErrorResponse
      if (errorResponse.errorFields && errorResponse.errorFields.length > 0) {
        const otherError = errorResponse.errorFields.find(ef => ef.name === 'Others')
        if (otherError) {
          axiosContext.addNotification({
            type: NotificationType.Warning,
            name: 'Chyba',
            text: otherError.errors,
          })
        }
        return Promise.reject(error)
      } else {
        axiosContext.addNotification({
          type: NotificationType.Danger,
          name: 'Chyba',
          text:
            errorResponse.message ||
            error.response.statusText ||
            'Došlo k neočekávané chybě při komunikaci se serverem',
        })
      }
    } else {
      axiosContext.addNotification({
        type: NotificationType.Danger,
        name: 'Chyba',
        text: 'Došlo k neočekávané chybě při komunikaci se serverem',
      })
    }
    return Promise.reject(error)
  }
)

const Router = () => (
  <BrowserRouter>
    <Routes>
      <Route element={<PrivateRoute />}>
        <Route path='/' element={<Home />} />
        <Route path='/script' element={<Script />} />
        <Route path='/script/update/:db?/:num?' element={<ScriptEdit />} />
        <Route path='/script/new/:db?' element={<ScriptNew />} />
        <Route path='/script/:db?/:num?' element={<Script />} />
        <Route path='/databases/batch-errors' element={<Databases batchErrorOnly={true} />} />
        <Route path='/databases' element={<Databases />} />
        <Route path='/sites/offline' element={<Sites onlyOffline={true} />} />
        <Route path='/sites' element={<Sites />} />
        <Route path='/site/:id' element={<SiteDetail />} />
        <Route path='/admin-queries' element={<AdminQueryIndex />} />
        <Route path='/admin-query/:id' element={<AdminQueryDetail />} />
        <Route path='/kb' element={<KbIndex />} />
        <Route path='/tools' element={<Tools />} />
        <Route path='/tools/ScriptGuide' element={<ScriptGuide />} />
        <Route path='/tools/EncDec' element={<EncDec />} />
        <Route path='/markdown/*' element={<MarkDownViewer />} />
      </Route>
    </Routes>
  </BrowserRouter>
)

export default Router
