import axios from 'axios'
import { useCallback, useContext, useEffect, useState } from 'react'
import { Alert, Col, Dropdown, ListGroup, ListGroupItem, Row } from 'react-bootstrap'
import { FaGithub, FaPlus, FaSitemap, FaTasks } from 'react-icons/fa'
import { Link, useNavigate } from 'react-router-dom'
import MainContainer from '../../comps/MainContainer'
import ScriptList from '../../comps/ScriptList'
import { API_BASE_URL } from '../../consts'
import ScriptContext from '../../contexts/ScriptContext'
import WebOnlineContext from '../../contexts/WebOnlineContext'
import MainLayout from '../../layouts/MainLayout'
import IScriptDetailItem from '../../types/IScriptDetailItem'

const Home = () => {
  const { batchErrors, webOnlineMonitors } = useContext(WebOnlineContext)
  const websOfflineCount = webOnlineMonitors?.monitors.filter(x => x.status !== 2).length ?? 0
  const [db, setDb] = useState<number>(1)
  const [scripts, setScripts] = useState<IScriptDetailItem[] | null>(null)
  const navigate = useNavigate()
  const { scriptDbs } = useContext(ScriptContext)

  const loadScripts = useCallback(async () => {
    const data = await axios.get<IScriptDetailItem[]>(`${API_BASE_URL}/Script/Last?db=${db}`)
    setScripts(data.data)
  }, [db])

  useEffect(() => {
    loadScripts()
  }, [loadScripts])

  return (
    <MainLayout>
      <MainContainer>
        <Row className='gx-4 my-4'>
          <Col>
            <Row className='gx-1'>
              <Col sm='auto'>
                <h6 className='mt-1'>Nejnovější skripty </h6>
              </Col>
              <Col>
                <Dropdown>
                  <Dropdown.Toggle variant='link' className='text-dark p-0'>
                    {scriptDbs[db - 1].name}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {scriptDbs.map(x => (
                      <Dropdown.Item key={x.id} onClick={() => setDb(x.id)}>
                        {x.name}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
              <Col sm='auto'>
                <Link to={`/Script/New/${db}`}>
                  <FaPlus />
                </Link>
              </Col>
            </Row>
            {scripts && (
              <ScriptList
                scripts={scripts}
                onScriptClicked={s => navigate(`/script/${s.databaseId}/${s.number}`)}
              ></ScriptList>
            )}
          </Col>
          <Col>
            <div className='mb-5 px-3'>
              <h6>
                <FaSitemap className='me-2' /> Weby offline
              </h6>
              {websOfflineCount > 0 && (
                <ListGroup>
                  {webOnlineMonitors?.monitors
                    .filter(x => x.status !== 2)
                    .map(x => (
                      <ListGroup.Item
                        key={x.id}
                        action
                        onClick={() => navigate('/sites/offline')}
                        variant='danger'
                      >
                        <div className='fw-bold'>{x.friendly_name}</div>
                        <a href={x.url} target='_blank' rel='noreferrer'>
                          {x.url}
                        </a>
                      </ListGroup.Item>
                    ))}
                </ListGroup>
              )}
              {websOfflineCount === 0 && (
                <Alert variant='success' className='c'>
                  Všechny weby jsou OK
                </Alert>
              )}
            </div>
            <div className='px-3'>
              <h6>
                <FaTasks className='me-2 text-danger' /> Chyby v dávkách
              </h6>
              {batchErrors && batchErrors.length > 0 && (
                <ListGroup>
                  {batchErrors.map(x => (
                    <ListGroup.Item
                      key={x.id}
                      action
                      onClick={() => navigate('/databases/batch-errors')}
                    >
                      <div className='fw-bold'>{x.companyName}</div>
                      {x.taskName}
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              )}
              {batchErrors && batchErrors.length === 0 && (
                <Alert variant='success' className='c'>
                  Všechny sledované dávky jsou OK
                </Alert>
              )}
            </div>
          </Col>
          <Col>
            <h6>
              <FaGithub className='me-2' /> Důležité repozitáře na GutHub
            </h6>
            <ListGroup>
              <ListGroupItem>
                <div className='fw-bold'>Dokumentace Benefit</div>
                <a href='https://github.com/BenefitCZ/benefit-doc' target='_blank' rel='noreferrer'>
                  https://github.com/BenefitCZ/benefit-doc
                </a>
              </ListGroupItem>
              <ListGroupItem>
                <div className='fw-bold'>Dokumentace Benefit - interní</div>
                <a
                  href='https://github.com/BenefitCZ/benefit-doc-internal'
                  target='_blank'
                  rel='noreferrer'
                >
                  https://github.com/BenefitCZ/benefit-doc-internal
                </a>
              </ListGroupItem>
              <ListGroupItem>
                <div className='fw-bold'>Benefit NuGet packages</div>
                <a href='https://github.com/BenefitCZ/net-common' target='_blank' rel='noreferrer'>
                  https://github.com/BenefitCZ/net-common
                </a>
              </ListGroupItem>
            </ListGroup>
          </Col>
        </Row>
      </MainContainer>
    </MainLayout>
  )
}

export default Home
