import { ReactNode } from 'react'
import TopMenu from '../comps/TopMenu'

type Props = {
  children?: ReactNode
  className?: string
}

const MainLayout = ({ children, className }: Props) => (
  <div className={`app-container ${className}`}>
    <div className='header'>
      <TopMenu />
    </div>
    <div className='main'>{children}</div>
  </div>
)

export default MainLayout
