import { useContext } from 'react'
import { Badge } from 'react-bootstrap'
import { FaTasks } from 'react-icons/fa'
import WebOnlineContext from '../contexts/WebOnlineContext'

const BatchErrorIcon = () => {
  const { batchErrors } = useContext(WebOnlineContext)

  return (
    <>
      {batchErrors && (
        <>
          <FaTasks className='icon' />
          <Badge
            pill
            bg={batchErrors.length > 0 ? 'danger' : 'success'}
            className='badge-top-index'
          >
            {batchErrors.length}
          </Badge>
        </>
      )}
    </>
  )
}

export default BatchErrorIcon
