import { createContext } from 'react'
import IScriptDb from '../types/IScriptDb'

interface IScriptContext {
  scriptDbs: IScriptDb[]
}

const ScriptContext = createContext<IScriptContext>({
  scriptDbs: [],
})

export default ScriptContext
