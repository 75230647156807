import axios from 'axios'
import { useCallback, useContext, useEffect, useState } from 'react'
import MainContainer from '../../comps/MainContainer'
import { API_BASE_URL } from '../../consts'
import MainLayout from '../../layouts/MainLayout'
import { IClientDatabase } from '../../types/IClientDatabase'
import IBnfTableDefColumn from '../../comps/bnfTable/IBnfTableDefColumn'
import BnfTable from '../../comps/bnfTable/BnfTable'
import { format, parseISO } from 'date-fns'
import Loading from '../../comps/Loading'
import IScriptDetailItem from '../../types/IScriptDetailItem'
import { Badge } from 'react-bootstrap'
import WebOnlineContext from '../../contexts/WebOnlineContext'
import { FaTasks } from 'react-icons/fa'
import BatchErrorModal from './BatchErrorModal'
import { IBatchResult } from '../../types/IBatchResult'

type Props = { batchErrorOnly?: boolean }

const Databases = ({ batchErrorOnly }: Props) => {
  const [scriptDb] = useState<number>(1) //jen databaze benefit
  const [databases, setDatabases] = useState<IClientDatabase[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [dbActualVersion, setDbActualVersion] = useState<number>(0)
  const { batchErrors } = useContext(WebOnlineContext)
  const errorDbIds = batchErrors?.map(b => b.clientDbId)

  const [batchErrorModalVisible, setBatchErrorModalVisible] = useState<boolean>(false)
  const [batchErrorModalTitle, setBatchErrorModalTitle] = useState<string>('')
  const [batchErrorModalData, setBatchErrorModalData] = useState<IBatchResult[] | null>(null)

  const fetchDatabases = useCallback(async () => {
    try {
      setLoading(true)
      const data = await axios.get<IClientDatabase[]>(
        `${API_BASE_URL}/ClientDatabase?scriptDb=${scriptDb}`
      )

      setDatabases(data.data)
    } finally {
      setLoading(false)
    }
  }, [scriptDb])

  const fetchLastScript = useCallback(async () => {
    const data = await axios.get<IScriptDetailItem[]>(
      `${API_BASE_URL}/Script/Last?db=${scriptDb}&count=1`
    )
    setDbActualVersion(data.data[0].number)
  }, [scriptDb])

  const ShowBatchErrorModal = (id: number) => {
    var d = databases.find(x => x.id === id)
    if (d) {
      setBatchErrorModalVisible(true)
      setBatchErrorModalTitle(d.companyName)
      setBatchErrorModalData(d.batchErrors)
    }
  }

  useEffect(() => {
    fetchDatabases()
  }, [fetchDatabases])

  useEffect(() => {
    fetchLastScript()
  }, [fetchLastScript])

  const databseTableDef: Array<IBnfTableDefColumn> = [
    {
      caption: 'Name',
      fieldName: 'companyName',
    },
    {
      caption: 'Database',
      fieldName: 'database',
    },
    {
      caption: 'Server',
      fieldName: 'server',
    },
    {
      caption: 'Version',
      fieldName: 'version',
      sortType: 'num',
    },
    {
      caption: '',
      fieldName: 'scriptDbId',
      sortType: 'none',
      filterType: 'none',
      valueFormat: (cell: any) => {
        const diff = parseInt(cell.row.values['version']) - dbActualVersion
        return (
          <div className='c'>
            {diff < 0 && <Badge bg={diff < -3 ? 'danger' : 'warning'}>{diff}</Badge>}
          </div>
        )
      },
    },
    {
      caption: 'Last synchro',
      fieldName: 'lastDate',
      valueFormat: ({ cell: { value } }: any) => {
        return <>{value && format(parseISO(value), 'yyyy-MM-dd HH:ss')}</>
      },
    },
    {
      caption: '',
      fieldName: 'id',
      sortType: 'none',
      filterType: 'none',
      valueFormat: (cell: any) => {
        return (
          <div className='nowrap'>
            <span
              className='cursor-pointer'
              onClick={() => {
                ShowBatchErrorModal(cell.row.values['id'])
              }}
            >
              {batchErrors?.find(x => x.clientDbId === cell.row.values['id']) && (
                <>
                  <FaTasks className='text-danger' />
                  <Badge bg='danger' pill className='badge-top-index'>
                    {batchErrors?.filter(x => x.clientDbId === cell.row.values['id']).length}
                  </Badge>
                </>
              )}
            </span>
          </div>
        )
      },
    },
    {
      caption: 'Admin',
      fieldName: 'adminUrl',
      valueFormat: ({ cell: { value } }: any) => {
        return (
          <a href={value} target='_blank' rel='noreferrer'>
            {value}
          </a>
        )
      },
    },
  ]

  return (
    <MainLayout>
      <MainContainer>
        {loading && <Loading />}
        {databases && !loading && (
          <div className='mt-3'>
            <BnfTable
              source={
                batchErrorOnly
                  ? databases.filter(x => errorDbIds?.find(f => f === x.id))
                  : databases
              }
              columnsDef={databseTableDef}
            />
          </div>
        )}

        <BatchErrorModal
          isVisible={batchErrorModalVisible}
          onHide={() => setBatchErrorModalVisible(false)}
          title={batchErrorModalTitle}
          data={batchErrorModalData}
        />
      </MainContainer>
    </MainLayout>
  )
}

export default Databases
