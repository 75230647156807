export const getInitials = (firstName: string, lastName: string) => {
  return firstName[0].toUpperCase() + lastName[0].toUpperCase()
}

export const getInitialsFromSingleName = (name: string | undefined | null) => {
  if (!name) return 'U'

  const parts = name.split(' ')
  if (parts.length === 1) return parts[0][0]

  return parts[0][0] + parts[1][0]
}
