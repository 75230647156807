import { Modal } from 'react-bootstrap'
import { IBatchResult } from '../../types/IBatchResult'

type Props = {
  isVisible: boolean
  onHide: () => void
  title: string
  data: IBatchResult[] | null
}

const BatchErrorModal = ({ isVisible, onHide, title, data }: Props) => {
  return (
    <Modal id='batch-modal' centered size='lg' show={isVisible} onHide={onHide} scrollable>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {data?.map((batchError, index) => {
          return (
            <div key={index}>
              <h5>{batchError.batchSetName}</h5>
              <div className='mb-2'>
                <span className='fw-bold'>{batchError.taskName}</span>
                <div className='text-muted'>[{batchError.executionDate}]</div>
              </div>
              <div className='text-break'>{batchError.errorMessage}</div>
              <hr />
            </div>
          )
        })}
      </Modal.Body>
    </Modal>
  )
}

export default BatchErrorModal
