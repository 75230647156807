import { useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import MainContainer from '../../comps/MainContainer'
import MainLayout from '../../layouts/MainLayout'
import MarkdownPreview from '@uiw/react-markdown-preview'
import axios from 'axios'
import { BASE_URL } from '../../consts'

const MarkDownViewer = () => {
  const location = useLocation()
  const [text, setText] = useState<string>('')

  const loadMarkdownFile = useCallback(async () => {
    const path = location.pathname.replace('/markdown/', '/doc/')
    const res = await axios.get(BASE_URL + path)

    setText(res.data)
  }, [location.pathname])

  useEffect(() => {
    loadMarkdownFile()
  }, [loadMarkdownFile])

  return (
    <MainLayout className='bg-light-gray'>
      <MainContainer className='mw-1200'>
        <div className='bg-white py-4 px-4'>
          <MarkdownPreview source={text} className='bg-white text-dark'></MarkdownPreview>
        </div>
      </MainContainer>
    </MainLayout>
  )
}

export default MarkDownViewer
