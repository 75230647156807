import { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'

type Props = {
  isVisible: boolean
  onCancel: () => void
  onSubmit: (user: string, password: string) => void
}

const SiteCredentialsModal = ({ isVisible, onCancel, onSubmit }: Props) => {
  const [user, setUser] = useState<string>('')
  const [password, setPassword] = useState<string>('')

  return (
    <Modal centered show={isVisible} onHide={onCancel} scrollable>
      <Modal.Header closeButton>
        <Modal.Title>Credentials</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='mb-2'>
          <input
            type='text'
            value={user}
            className='form-control'
            placeholder='User'
            onChange={e => setUser(e.target.value)}
          />
        </div>
        <input
          type='password'
          value={password}
          className='form-control'
          placeholder='Heslo'
          onChange={e => setPassword(e.target.value)}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={() => onCancel()}>
          Storno
        </Button>
        <Button variant='primary' onClick={() => onSubmit(user, password)}>
          Uložit
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default SiteCredentialsModal
