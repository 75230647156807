import axios from 'axios'
import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import Loading from '../../../comps/Loading'
import MainContainer from '../../../comps/MainContainer'
import { API_BASE_URL } from '../../../consts'
import MainLayout from '../../../layouts/MainLayout'
import './EncDec.scss'

const EncDec = () => {
  const [password, setPassword] = useState('')
  const [value, setValue] = useState('')
  const [result, setResult] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')

  const onEncodeOrDecode = async (isDecrypt: boolean) => {
    if (!password || !value) {
      setError('Zadejte heslo i text')
      return
    }
    setError('')
    setIsLoading(true)

    try {
      const data = await axios.post<string>(
        `${API_BASE_URL}/EncDec/${isDecrypt ? 'Decrypt' : 'Encrypt'}`,
        { password: password, value: value }
      )
      if (data.status >= 200 && data.status < 300) {
        setResult(data.data)
      } else {
        setError(data.statusText)
      }
    } catch (e) {
      console.log(e)
      setError('Chyba při komunikaci se serverem')
    } finally {
      setIsLoading(false)
    }
  }

  return (
    //TODO: udelat container pro uzky form
    <MainLayout>
      <MainContainer className='mw-1200 p-3'>
        <section id='encdec-wrapper'>
          {isLoading ? (
            <Loading />
          ) : (
            <>
              <h3 className='pb-3 pt-3'>EncDec</h3>
              <Row>
                <Col className='mb-3'>
                  <div className='form-group row'>
                    <label htmlFor='value' className='col-sm-3 col-form-label'>
                      Text:
                    </label>
                    <div className='col-sm-9'>
                      <input
                        type='text'
                        className='form-control'
                        id='inputValue'
                        placeholder='Text...'
                        value={value}
                        onChange={e => setValue(e.target.value)}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className='mb-3'>
                  <div className='form-group row'>
                    <label htmlFor='password' className='col-sm-3 col-form-label'>
                      Heslo:
                    </label>
                    <div className='col-sm-9'>
                      <input
                        type='text'
                        className='form-control'
                        id='inputPassword'
                        placeholder='Heslo...'
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className='mb-3'>
                  <div className='form-group row'>
                    <label htmlFor='value' className='col-sm-3 col-form-label'>
                      Výsledek:
                    </label>
                    <div className='col-sm-9'>
                      <input
                        readOnly
                        type='text'
                        className='form-control'
                        id='inputValue'
                        value={result}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className='mb-3'>
                  <div className='d-flex flex-row justify-content-center'>
                    <button className='btn btn-danger m-2' onClick={() => onEncodeOrDecode(false)}>
                      Encode
                    </button>
                    <button className='btn btn-success m-2' onClick={() => onEncodeOrDecode(true)}>
                      Decode
                    </button>
                  </div>
                </Col>
              </Row>
              <div>
                {error && (
                  <div className='d-flex flex-row justify-content-center alert alert-danger'>
                    <h5>{error}</h5>
                  </div>
                )}
              </div>
            </>
          )}
        </section>
      </MainContainer>
    </MainLayout>
  )
}

export default EncDec
