import axios from 'axios'
import { format } from 'date-fns'
import { useCallback, useEffect, useState } from 'react'
import { Col, ListGroup, Row } from 'react-bootstrap'
import { FaEdit } from 'react-icons/fa'
import { useParams } from 'react-router-dom'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import MainContainer from '../../comps/MainContainer'
import { API_BASE_URL } from '../../consts'
import MainLayout from '../../layouts/MainLayout'
import IAdminQuery from '../../types/IAdminQuery'
import IAdminQueryResult from '../../types/IAdminQueryResult'
import AdminQueryEditModal from './AdminQueryEditModal'

const AdminQueryDetail = () => {
  const [id] = useState<number>(Number(useParams<{ id: string }>().id))
  const [query, setQuery] = useState<IAdminQuery | null>(null)
  const [activeResult, setActiveResult] = useState<IAdminQueryResult | null>(null)
  const [editModalVisible, setEditModalVisible] = useState<boolean>(false)

  const loadQuery = useCallback(async () => {
    const result = await axios.get<IAdminQuery>(`${API_BASE_URL}/AdminQuery/Detail/${id}`)
    setQuery(result.data)
    if (result.data.results) setActiveResult(result.data.results[0])
  }, [id])

  const editQuery = async (name: string, sql: string, archive: boolean) => {
    setEditModalVisible(false)
    if (query) {
      await axios.post(`${API_BASE_URL}/AdminQuery/update`, {
        id: query.id,
        name: name,
        sql: sql,
        archive: archive,
      })

      setQuery({ ...query, sql: sql, archive: archive })
    }
  }

  useEffect(() => {
    loadQuery()
  }, [loadQuery])

  return (
    <MainLayout>
      <MainContainer>
        {query && (
          <>
            {activeResult && (
              <Row>
                <Col>
                  <span className='fs-8'>
                    {format(new Date(activeResult.date), 'yyyy-MM-dd hh:mm:ss')}
                  </span>
                </Col>
                <Col className='r'>
                  <FaEdit className='cursor-pointer' onClick={() => setEditModalVisible(true)} />
                </Col>
              </Row>
            )}

            <SyntaxHighlighter language='sql' wrapLongLines={true}>
              {query.sql}
            </SyntaxHighlighter>
            {activeResult && (
              <Row className='mt-3'>
                <Col sm={3}>
                  <ListGroup>
                    {query.results.map(r => (
                      <ListGroup.Item
                        key={r.id}
                        action
                        onClick={() => setActiveResult(r)}
                        active={r.id === activeResult.id}
                      >
                        {r.clientDatabaseCompany}
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                </Col>
                <Col sm={9}>
                  <SyntaxHighlighter language='json' wrapLongLines={true}>
                    {JSON.stringify(JSON.parse(activeResult.result), null, 2)}
                  </SyntaxHighlighter>
                </Col>
              </Row>
            )}
            <AdminQueryEditModal
              query={query}
              onCancel={() => setEditModalVisible(false)}
              onSubmit={(name, sql, archive) => editQuery(name, sql, archive)}
              isVisible={editModalVisible}
            />
          </>
        )}
      </MainContainer>
    </MainLayout>
  )
}

export default AdminQueryDetail
