import React, { useEffect } from 'react'
import Router from './services/Router'
import axios from 'axios'
import { API_BASE_URL } from './consts'
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsalAuthentication,
} from '@azure/msal-react'
import { InteractionType } from '@azure/msal-browser'
import { loginRequest } from './authUtils'
import ScriptContextProvider from './contexts/ScriptContextProvider'
import WebOnlineContextProvider from './contexts/WebOnlineContextProvider'
import NotificationsProvider from './contexts/NotificationsProvider'
import { AuthProvider } from './contexts/AuthContext'

const InitAxios = () => {
  axios.defaults.baseURL = API_BASE_URL

  axios.interceptors.request.use(config => {
    config.headers['Authorization'] =
      'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzeXN0ZW0iOiIyMDAxIn0.CCTf_T71FEqQwKkXFxn_wtSJoajUfewrXLhRQ4ryKgQ'
    //console.debug('axios URL:' + config.url)
    return config
  })

  axios.interceptors.response.use(
    response => {
      return response
    },
    error => {
      // if (error.response?.status === 401) {
      //   window.location.href = 'login'
      // }
      return Promise.reject(error)
    }
  )
}

document.title = 'DevBen'

const App = () => {
  const { login } = useMsalAuthentication(InteractionType.Silent, loginRequest)

  InitAxios()

  useEffect(() => {
    login()
  }, [login])

  return (
    <AuthProvider>
      <NotificationsProvider>
        <ScriptContextProvider>
          <WebOnlineContextProvider>
            <AuthenticatedTemplate>
              <Router />
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
              <div className='w-100 h-100 d-flex flex-column justify-content-center align-items-center'>
                <div>Probíhá přihlašování...</div>
                <div>
                  Pokud se nic neděje, nebo se přihlašujete poprvé, klikněte{' '}
                  <span
                    role='button'
                    className='fw-bold'
                    onClick={() => login(InteractionType.Redirect)}
                  >
                    sem
                  </span>
                </div>
              </div>
            </UnauthenticatedTemplate>
          </WebOnlineContextProvider>
        </ScriptContextProvider>
      </NotificationsProvider>
    </AuthProvider>
  )
}

export default App
