import { createContext } from 'react'
import { IBatchResult } from '../types/IBatchResult'
import IUptimeData from '../types/IUptimeData'

interface IWebOnlineContext {
  webOnlineMonitors: IUptimeData | null
  batchErrors: IBatchResult[] | null
}

const WebOnlineContext = createContext<IWebOnlineContext>({
  webOnlineMonitors: null,
  batchErrors: null,
})

export default WebOnlineContext
