import axios from 'axios'
import { useCallback, useContext, useEffect, useState } from 'react'
import { Button, Col, FloatingLabel, Form, Row } from 'react-bootstrap'
import { SubmitHandler, useForm } from 'react-hook-form'
import { FaKey } from 'react-icons/fa'
import { useParams } from 'react-router-dom'
import MainContainer from '../../comps/MainContainer'
import { API_BASE_URL } from '../../consts'
import NotificationsContext from '../../contexts/NotificationsContext'
import MainLayout from '../../layouts/MainLayout'
import { ISiteInfo } from '../../types/ISiteInfo'
import { NotificationType } from '../../types/NotificationModel'
import SiteCredentialsModal from './SiteCredetialsModal'

const SiteDetail = () => {
  const [id] = useState<number>(parseInt(useParams()?.id ?? '0'))
  const [site, setSite] = useState<ISiteInfo | null>()
  const [credentialsModalVisible, setCredentialsModalVisible] = useState<boolean>(false)
  const { addNotification } = useContext(NotificationsContext)

  const {
    register,
    handleSubmit,
    // formState: { errors },
    reset,
  } = useForm<ISiteInfo>({ defaultValues: { ...site } })

  const onSubmit: SubmitHandler<ISiteInfo> = async data => {
    await axios.post(`${API_BASE_URL}/Site/update`, data)
    addNotification({
      type: NotificationType.Success,
      name: 'OK',
      text: 'Údaje byly úspěšně uloženy.',
    })
  }

  const loadSite = useCallback(async () => {
    const res = await axios.get<ISiteInfo>(`${API_BASE_URL}/Site/detail?id=${id}`)
    setSite(res.data)
  }, [id])

  const saveCredentials = async (user: string, password: string) => {
    setCredentialsModalVisible(false)
    if (site) {
      await axios.post(`${API_BASE_URL}/Site/change-credentials`, {
        id: site.id,
        publishCredentials: `${user}|${password}`,
      })
      addNotification({
        type: NotificationType.Success,
        name: 'OK',
        text: 'Údaje byly úspěšně uloženy.',
      })
    }
  }

  useEffect(() => {
    loadSite()
  }, [loadSite])

  useEffect(() => {
    if (site) reset(site)
  }, [reset, site])

  return (
    <MainLayout>
      <MainContainer className='mw-1200 py-3'>
        {site && (
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col>
                <FloatingLabel label='Jméno' className='mb-3'>
                  <Form.Control {...register('name')} disabled />
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel label='Type' className='mb-3'>
                  <Form.Select {...register('type')} disabled>
                    <option value={-1}>Admin</option>
                    <option value={0}>B2B</option>
                    <option value={1}>B2C</option>
                  </Form.Select>
                </FloatingLabel>
              </Col>
            </Row>
            <Row>
              <Col>
                <FloatingLabel label='Url' className='mb-3'>
                  <Form.Control {...register('url')} />
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel label='External ID' className='mb-3'>
                  <Form.Control {...register('externalId')} />
                </FloatingLabel>
              </Col>
            </Row>
            <Row>
              <Col>
                <FloatingLabel label='Publishing project name' className='mb-3'>
                  <Form.Control {...register('publishProjectName')} />
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel label='Publish profile' className='mb-3'>
                  <Form.Control {...register('publishProfile')} />
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel label='IIS app' className='mb-3'>
                  <Form.Control {...register('iisAppName')} />
                </FloatingLabel>
              </Col>
            </Row>
            <Row>
              <Col>
                <FloatingLabel label='Publish credentials' className='mb-3'>
                  <Form.Control {...register('publishCredentials')} disabled />
                </FloatingLabel>
              </Col>
            </Row>
            <Row>
              <Col>
                <FloatingLabel label='Last publishing user' className='mb-3'>
                  <Form.Control {...register('lastPublishUser')} disabled />
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel label='Last publishing date' className='mb-3'>
                  <Form.Control {...register('lastPublishDate')} disabled />
                </FloatingLabel>
              </Col>
            </Row>
            <Row>
              <Col>
                <FloatingLabel label='Orders per year' className='mb-3'>
                  <Form.Control {...register('orderCount365')} disabled />
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel label='Orders per week' className='mb-3'>
                  <Form.Control {...register('orderCount7')} disabled />
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel label='Orders per day' className='mb-3'>
                  <Form.Control {...register('orderCount24')} disabled />
                </FloatingLabel>
              </Col>
            </Row>
            <Form.Group>
              <Row>
                <Col>
                  <Button type='submit'>Uložit</Button>
                </Col>
                <Col sm='auto' className='r'>
                  <Button
                    type='button'
                    variant='secondary'
                    onClick={() => setCredentialsModalVisible(true)}
                  >
                    <FaKey className='me-1' />
                    Změnit publish credentials
                  </Button>
                </Col>
              </Row>
            </Form.Group>
          </Form>
        )}

        <SiteCredentialsModal
          isVisible={credentialsModalVisible}
          onCancel={() => setCredentialsModalVisible(false)}
          onSubmit={(user, password) => saveCredentials(user, password)}
        />
      </MainContainer>
    </MainLayout>
  )
}

export default SiteDetail
